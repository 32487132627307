import {Injectable} from '@angular/core';
import {_} from '../../../oj-app-common/i18n/translate-placeholder';
import {CurrentUserService} from '../auth/current-user.service';
import {LoadingService} from './loading.service';
import {AppConfig} from '../config/app-config';
import {CurrentCourseService} from './current-course-service';
import {LoginService} from './login.service';
import {Router} from '@angular/router';
import {AppTranslationService} from './app-translation.service';
import {ThemeService} from '../theme/theme.service';
import {ServicesAccessor} from '../../../oj-app-common/tools/services-accessor';
import {HelpcrunchService} from './helpcrunch.service';
import {StudovnaApi} from '../../../oj-app-common/studovna-api/studovna-api';
import {PurchaseService} from '../pages/base-layout-page/pages/eshop-page/services/purchase.service';
import {Title} from '@angular/platform-browser';
import {appCodes, appSecrets} from '../config/app.config';
import {EshopUserPreferencesService} from '../pages/base-layout-page/pages/eshop-page/services/eshop-user-preferences.service';
import {AppNavigateService} from './app-navigate.service';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {

  constructor(
    protected currentUserService: CurrentUserService,
    protected loadingService: LoadingService,
    protected appConfig: AppConfig,
    protected currentCourseService: CurrentCourseService,
    protected loginService: LoginService,
    protected router: Router,
    protected appTranslationService: AppTranslationService,
    protected themeService: ThemeService,
    protected servicesAccessor: ServicesAccessor,
    protected helpcrunch: HelpcrunchService,
    protected studovnaApi: StudovnaApi,
    protected purchaseService: PurchaseService,
    protected title: Title,
    protected appNavigateService: AppNavigateService,
    protected eshopPrefs: EshopUserPreferencesService
  ) {
  }

  public async appInit() {
    this.servicesAccessor.init();

    console.log(this.servicesAccessor);

    this.themeService.setActiveTheme(this.themeService.getActiveTheme());

    await this.appTranslationService.initApp();

    if (this.currentUserService.isLoggedIn) {
      this.loadingService.show(_('Přihlašuji...'));
    }

    if (this.appConfig.debugSkipInitialVerifyToken && this.currentUserService.isLoggedIn) {
      await this.helpcrunch.initialize();
      const restore = this.currentCourseService.restoreDataFromDevCache();
      if (restore) {
        // this.toleranceSettings.init();
        this.loadingService.hide();
        return;
      }
    }

    const isPendingPurchase = this.purchaseService.getPurchaseFromLocalStorage();

    return this.loginService
      .appInitialize()
      .then((canContinue) => {
        if (canContinue) {
          // this.loadingService.hide();
          // this.toleranceSettings.init();
          this.helpcrunch.initialize();

          this.eshopPrefs.load();

          const storedUrlAfterLogin = this.appNavigateService.getRedirectUrl();

          const isStoredUrlTrialPicker = storedUrlAfterLogin && storedUrlAfterLogin.indexOf('trialPicker') !== -1;

          const isCurrentUrlTrialPicker = window.location.href.indexOf('trialPicker') !== -1;

          this.studovnaApi.courseList(this.currentUserService.studovnaId, this.currentUserService.token, isStoredUrlTrialPicker || isCurrentUrlTrialPicker)
            .then(courses => {

              const currentUrl = this.router.url;

              if (isStoredUrlTrialPicker) {
                setTimeout(() => {
                  this.router.navigate([storedUrlAfterLogin]);
                  this.appNavigateService.removeRedirectUrl();
                }, 200);
                return;
              }

              if (this.currentUserService.profile.activeCourse) {

                if (currentUrl) {
                  this.router.navigate([currentUrl]);
                  return;
                }
                this.router.navigate(['/dashboard']);
                return;
              }
              if (!this.currentUserService.profile.activeCourse) {
                // if (courses.courses) {
                //   this.router.navigate(['/my-courses'])
                //   return;
                // }
                this.router.navigate(['/trialPicker']);
                return;
              } else {
                this.router.navigate(['/eshop']);
                return;
              }
            })
            .catch(e => {
              this.router.navigate(['/dashboard']);
            });

        } else {
          this.loadingService.hide();
        }
        return canContinue;
      })
      .catch((e) => {
        console.log(e)
        this.loadingService.hide();
      });
  }

  public async setAppBranding() {
    const url = location.host.split('.');
    /* SET MAIN THEME OF AN APP */
    if (url && url.length === 3) {
      const domain = url[1];
      if (domain && domain.toLowerCase() === 'easylingo') {
        this.appConfig.currentApp = this.appConfig.apps.easylingo;
        this.appConfig.brandCode = 'el';
        this.appConfig.brandAppName = 'EasyLingo';
        this.appConfig.eshopAppCode = appCodes.easylingo;
        this.appConfig.eshopAppSecret = appSecrets.easylingo;
        this.appConfig.supportEmail = 'info@easylingo.com';
        this.title.setTitle('EasyLingo');
        this.appConfig.brandLogoFilename = 'easy_lingo_logo';
        document.getElementById('theme').setAttribute('href', 'assets/css/easy_lingo_theme_dark.css');
        document.title = 'EasyLingo';
        document.getElementById('icon').setAttribute('href', 'assets/img/easy_lingo_logo.png');
      } else {
        this.appConfig.currentApp = this.appConfig.apps.onlinejazyky;
        this.appConfig.brandCode = 'oj';
        this.appConfig.brandAppName = 'OnlineJazyky';
        this.appConfig.brandLogoFilename = 'oj-logo';
        this.appConfig.eshopAppCode = appCodes.onlinejazyky;
        this.appConfig.eshopAppSecret = appSecrets.onlinejazyky;
        this.appConfig.supportEmail = 'podpora@onlinejazyky.cz';
        this.title.setTitle('OnlineJazyky');
        document.getElementById('theme').setAttribute('href', 'assets/css/online_jazyky_theme.css');
        document.title = 'OnlineJazyky';
        document.getElementById('icon').setAttribute('href', 'assets/img/oj-logo.png');
      }
      const language = url[2];
      if (!this.appTranslationService.hasSavedLanguage) {
        if (language === 'com') {
          const userLang = navigator.language;

          if (userLang) {
            if (userLang.includes('es')) {
              await this.appTranslationService.switchLanguage('es');
            } else {
              await this.appTranslationService.switchLanguage('en');
            }
          } else {
            await this.appTranslationService.switchLanguage('en');
          }
        } else {
          await this.appTranslationService.switchLanguage('cs');
        }
      }
      this.themeService.setLightTheme();
    }
  }
}
