import { MethodDescription } from '../method-description';

export const GetSignWithAppleInitParameters = new MethodDescription();

GetSignWithAppleInitParameters.name = 'getSignWithAppleInitParameters';
GetSignWithAppleInitParameters.fields = [
	'brand',
	'after',
];
GetSignWithAppleInitParameters.usesToken = false;
