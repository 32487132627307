import { Injectable, ErrorHandler } from '@angular/core';
import * as sha1 from 'sha1';
import { MethodDescription } from './method-description';
import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
	CheckLoginResponse, EditStudentResponse, FacebookLoginResponse, GetCoursesResponse, IsUsernameFreeResponse,
	LoginResponse, LogoutResponse,
	RestorePassword2Response,
	RestorePasswordResponse,
	TestResponse,
	VerifyTokenResponse
} from './methods/responses';
import { EditProfileRequest, TestRequest } from './methods/requests';
import { TestMethodDescription } from './methods/test';
import { CheckLoginMethodDescription } from './methods/check-login';
import { GetCoursesMethodDescription } from './methods/get-courses';
import { Token } from '../misc/token';
import { VerifyTokenMethodDescription } from './methods/verify-token';
import { LoginMethodDescription } from './methods/login';
import { LogoutMethodDescription } from './methods/logout';
import { EditStudentMethodDescription } from './methods/edit-student';
import { IsUsernameFreeMethodDescription } from './methods/is-username-free';
import { EditProfileMethodDescription } from './methods/edit-profile';
import { ServicesAccessor } from '../tools/services-accessor';
import { RestorePasswordMethodDescription } from './methods/restore-password';
import { RestorePassword2MethodDescription } from './methods/restore-password2';
import { FacebookLoginMethodDescription } from './methods/facebook-login';
import { parseTime } from '../tools/parse-time';
import { MinAppVersionDescription } from './methods/min-app-version';
import { AppVersionConstrain } from './models/app-version-constrain';
import { CreateStudentMethodDescription } from './methods/create-student';
import { GetAvailableDemosMethodDescription } from './methods/get-available-demos';
import { AvailableDemo } from './models/available-demo';
import { ActivateDemoMethodDescription } from './methods/activate-demo';
import { AppConfigEshopApi } from './app-config-eshop-api';
import { GetExpiryDateMethodDescription } from './methods/get-expiry-date';
import { ConvertIdMethodDescription } from './methods/convert-id';
import { VerifyAppStoreReceiptDescription } from './methods/verify-app-store-receipt';
import { ProductShopDetailsDescription } from './methods/product-shop-details';
import { ProductShopDetails } from './models/product-shop-details';
import { HashStudentIdMethodDescription } from './methods/hash-student-id';
import { ProductData } from './models/product-data';
import { GetProductDataMethodDescription } from './methods/get-product-data';
import { FavoriteCourseMethodDescription } from './methods/favorite-course';
import { DeactivateDemoMethodDescription } from './methods/deactivate-demo';
import { StarCourseMethodDescription } from './methods/star-course';
import { BlockStudentAccountDescription } from './methods/block-student-account';
import { createDashboardBanner, DashboardBanner } from './models/dashboard-banner';
import { DashboardBannersDescription } from './methods/dashboard-banners';
import { Brand } from '../shop/types/brand';
import { SignWithAppleInitParameters } from './models/sign-with-apple-init-parameters';
import { GetSignWithAppleInitParameters } from './methods/get-sign-with-apple-init-parameters';

@Injectable()
export class EshopApi {

	protected axios: AxiosInstance;

	protected errorHandler: ErrorHandler;

	constructor(
		protected appConfig: AppConfigEshopApi,
		serviceAccessor: ServicesAccessor,
		errorHandler: ErrorHandler,
	) {
		// if (errorHandler['isAppHandler']) {
			// This leads to custom app's error handler derived from Ionic, that was
			// too difficult to separate from rest of the mobile app.
			// Can be safely used with standard Angular's ErrorHandler
			this.errorHandler = errorHandler /* as AppErrorHandler */;
		// }

		this.axios = Axios.create({
			baseURL: appConfig.eshopApiUrl,
			timeout: 10000,
			headers: {
				// 'X-OJ-App': 'unknown yet',
			},
			validateStatus: (status: number) => {
				// Status 500 is handled later
				if (status === 200 || status === 500 || status === 403) return true;
				return false;
			}
		});

		serviceAccessor.add('eshopApi', this);
	}

	public test(input: TestRequest): Promise<TestResponse> {

		return <Promise<TestResponse>>this.call(
			TestMethodDescription,
			input
		);

	}

	public checkLogin(email: string, password: string): Promise<CheckLoginResponse> {

		return <Promise<CheckLoginResponse>>this.call(
			CheckLoginMethodDescription,
			{
				email: email,
				password: password
			}
		)
			.then(
				(response: CheckLoginResponse) => {
					response.correctPassword = !!response.correctPassword;
					response.found = !!response.found;
					return Promise.resolve(response);
				}
			)
			;

	}

	public getCourses(id: number, token: Token): Promise<GetCoursesResponse> {

		return <Promise<GetCoursesResponse>>this.call(
			GetCoursesMethodDescription,
			{
				id: id
			},
			token
		)
			.then(
				(response: GetCoursesResponse) => {
					for (const course of response.courses) {
						if (course.expireDate) {
							try {
								course.expireDate = parseTime(course.expireDate);
							} catch (e) {
								console.error(e);
								course.expireDate = null;
							}
						}
					}
					return response;
				}
			)
			;

	}

	public verifyToken(token: Token): Promise<VerifyTokenResponse> {

		return <Promise<VerifyTokenResponse>>this.call(
			VerifyTokenMethodDescription,
			{},
			token
		)
			.then(
				(response: VerifyTokenResponse) => {
					if (response.expires) {
						try {
							response.expires = parseTime(response.expires);
						} catch (error) {
							response.expires = null;
							console.error(error);
						}
					}
					if (response.userData) {
						if (response.userData.id && typeof response.userData.id === 'string') {
							response.userData.id = parseInt(response.userData.id);
						}
						if (!response.userData.id) {
							response.userData = null;
						}
					}
					response.valid = !!response.valid;
					return response;
				})
			;

	}


	public login(email: string, password: string): Promise<LoginResponse> {
		return <Promise<LoginResponse>>this.call(
			LoginMethodDescription,
			{
				email: email,
				password: password,
				ip: '',
				app: 1
			}
		)
			.then(
				(response: LoginResponse) => {
					if (response && response.token) {
						return response;
					}
					throw new Error('Returned response did not contain any token.');
				}
			)
			;
	}

	public logout(token: Token, allFromUserId?: number): Promise<LogoutResponse> {

		return <Promise<LogoutResponse>>this.call(
			LogoutMethodDescription,
			{
				allFromUserId: +allFromUserId,
				thisToken: token,
			},
			token
		);

	}

	public changePassword(studentId: number, token: Token, newPassword: string): Promise<EditStudentResponse> {

		return this.call(
			EditStudentMethodDescription,
			{
				id: studentId,
				password: newPassword,
			},
			token
		)
		.then(
			(response: EditStudentResponse) => {
				response.changed = !!response.changed;
				return response;
			}
		);

	}

	public changeEmail(studentId: number, token: Token, newEmail: string): Promise<EditStudentResponse> {

		return this.call(
			EditStudentMethodDescription,
			{
				id: studentId,
				email: newEmail,
			},
			token
		)
			.then(
				(response: EditStudentResponse) => {
					response.changed = !!response.changed;
					return response;
				}
			);

	}


	public isUsernameFree(studentId: number, email: string, token: Token): Promise<IsUsernameFreeResponse> {

		return this.call(
			IsUsernameFreeMethodDescription,
			{
				email: email,
				userId: studentId,
			},
			token
		)
		.then(
			(response: IsUsernameFreeResponse) => {
				response.isFree = !!response.isFree;
				return response;
			}
		);

	}

	public createStudent(email: string, password: string, languageCode = '', referrer = '', partnerCompany = '', partnerId = ''): Promise<number> {

		return this.call(
			CreateStudentMethodDescription,
			{
				email: email,
				password: password,
				language: languageCode,
				referrer: referrer,
				partnerCompany: partnerCompany,
				partnerId: partnerId,
			}
		)
			.then(
				(response) => {
					if (response['id']) {
						return +response['id'];
					}
				}
			);

	}

	public editProfile(studentId: number, token: Token, profile: EditProfileRequest): Promise<EditStudentResponse> {

		const apiParams = Object.assign(
			{id: studentId},
			profile
		);

		return <Promise<EditStudentResponse>>this.call(EditProfileMethodDescription, apiParams, token);

	}

	public restorePassword(email: string): Promise<RestorePasswordResponse> {
		return this.call(RestorePasswordMethodDescription, {email: email})
			.then(
				(response: any) => {
					response.successful = !!response.successful;
					response.userFound = !!response.userFound;
					response.requestId = +response.requestId || 0;
					response.userId = +response.userId || 0;
					response.lockedUntil = response.lockedUntil ? parseTime(response.lockedUntil) : null;
					response.validUntil = response.validUntil ? parseTime(response.validUntil) : null;
					return response;
				}
			)
		;
	}

	public restorePassword2(code: number, requestId: number, userId: number, password: string): Promise<RestorePassword2Response> {
		return this.call(
			RestorePassword2MethodDescription,
			{
				code: code,
				requestId: requestId,
				userId: userId,
				password: password,
			},
		)
			.then(
				(response: any) => {
					response.successful = !!response.successful;
					response.failReason = +response.failReason || 0;
					return response;
				}
			)
		;
	}

	public facebookLogin(fbToken: string): Promise<FacebookLoginResponse> {
		return this.call(
			FacebookLoginMethodDescription,
			{
				fbToken: fbToken
			}
		)
			.then(
				(response: FacebookLoginResponse) => {
					response.id = +response.id;
					response.created = !!response.created;
					return response;
				}
			)

			;
	}

	public minAppVersion(): Promise<AppVersionConstrain[]> {
		return this.call(
			MinAppVersionDescription,
			{}
		)
			.then(
				(response: any) => {
					if (!response['versions']) {
						return [];
					}
					let returned: AppVersionConstrain[] = [];
					for (let row of response['versions']) {
						returned.push({
							since: parseTime(row['since']),
							version: row['version'],
						});
					}
					return returned;
				}
			)

			;
	}

	public getAvailableDemos(studentId: number, token: Token, includeHidden = false): Promise<AvailableDemo[]> {

		return this.call(
			GetAvailableDemosMethodDescription,
			{
				studentId: studentId,
				includeHidden: includeHidden ? '1' : '0',
			},
			token
		).then(
			(response) => {
				let courses = response['courses'];
				courses.forEach(
					(c) => {
						if (c.expireDate) {
							c.expireDate = parseTime(c.expireDate, true);
						} else {
							c.expireDate = null;
						}
					}
				);
				return courses;
			}
		);

	}

	public activateDemo(productId: number, studentId: number, sourceCode: string, token: Token): Promise<{activated: boolean, courseId: number}> {

		return this.call(
			ActivateDemoMethodDescription,
			{
				productId: productId,
				studentId: studentId,
				sourcecode: sourceCode,
				expireRenewalUrl: '',
				sendToPodio: 1,
			},
			token
		)
			.then(
				(response) => {
					return {
						activated: !!response['activated'],
						courseId: +response['courseId'],
					};
				}
			)
		;

	}

	public deactivateDemo(productId: number, studentId: number, token: Token): Promise<{deactivated: boolean}> {

		return this.call(
			DeactivateDemoMethodDescription,
			{
				productId,
				studentId,
				sendToPodio: 1,
			},
			token
		)
			.then(
				(response) => ({
					deactivated: !!response['deactivated'],
				})
			)
		;

	}

	public getExpireDate(productId: number, studentId: number, token: Token): Promise<{expires: Date, renewalUrl: string, active: boolean}> {

		return this.call(
			GetExpiryDateMethodDescription,
			{
				productId: productId,
				studentId: studentId,
			},
			token
		)
			.then(
				(response) => {
					return {
						active: !!response['active'],
						expires: response['expires'] ? parseTime(response['expires'], true) : null,
						renewalUrl: response['renewalUrl'],
					};
				}
			)
			;

	}

	public convertId(id: string, from: ('product'|'course'|'podio'), to: ('product'|'course'|'podio')): Promise<number> {

		return this.call(
			ConvertIdMethodDescription,
			{
				id: id,
				from: from,
				to: to,
			}
		)
			.then(
				(response) => {
					return (response['id'] || 0);
				}
			)
			;

	}

	public changeLanguage(studentId: number, newLanguageCode: string, token: Token): Promise<boolean> {
		return this.call(
			EditStudentMethodDescription,
			{
				id: studentId,
				language: newLanguageCode,
			},
			token
		).then(
			(response) => !!response['changed']
		);
	}

	public verifyAppStoreReceipt(userId: number, transactionId: string, receiptData: string, appIdentifier: string, token: Token): Promise<boolean> {
		return this.call(
			VerifyAppStoreReceiptDescription,
			{
				userId: userId,
				transactionId: transactionId,
				receipt: receiptData,
				appIdentifier: appIdentifier,
			},
			token
		).then(
			(response) => !!response['successful']
		);
	}

	public productShopDetails(productId: number): Promise<ProductShopDetails> {

		return this.call(
			ProductShopDetailsDescription,
			{
				productId: productId,
			},
		).then((response) => response as ProductShopDetails);

	}

	public getProductData(productId: number, token: Token, studentId: number = null): Promise<ProductData> {

		return this.call(
			GetProductDataMethodDescription,
			{
				id: productId,
				studentId,
			},
			token
		).then(
			(response: any) => {
				if (response.productData) {
					if (response.productData.expireDate) {
						response.productData.expireDate = parseTime(response.productData.expireDate);
					}
					return response.productData;
				}
				return null;
			}
		);
	}

	public favoriteCourse(studentId: number, productId: number, isFavorite: boolean, token: Token): Promise<{favorites: number[]}> {
		return this.call(
			FavoriteCourseMethodDescription,
			{
				studentId,
				productId,
				favorite : (isFavorite ? '1' : '0'),
			},
			token
		).then(
			(resp: any) => {
				if (resp.favorites) {
					return {
						favorites: resp.favorites.map(a => +a),
					};
				}
				return {
					favorites: [],
				};
			}
		);
	}

	public starCourse(studentId: number, productId: number, isStarred: boolean, token: Token): Promise<{starred: number[]}> {
		return this.call(
			StarCourseMethodDescription,
			{
				studentId,
				productId,
				starred : (isStarred ? '1' : '0'),
			},
			token
		).then(
			(resp: any) => {
				if (resp.starred) {
					return {
						starred: resp.starred.map(a => +a),
					};
				}
				return {
					starred: [],
				};
			}
		);
	}

	public hashStudentId(id: number, token: Token): Promise<string> {
		return this.call(
			HashStudentIdMethodDescription,
			{
				studentId: id,
			},
			token
		).then(
			(response) => (response['hashedStudentId'] || '')
		);
	}

	public blockStudentAccount(studentId: number, token: Token): Promise<boolean> {
		return this.call(
			BlockStudentAccountDescription,
			{
				studentId,
				block: 1,
			},
			token
		).then(
			(response) => !!response['success']
		);
	}

	public dashboardBanners(language: 'cs' | 'sk' | 'en' | 'pl' | 'es', token: Token): Promise<DashboardBanner[]> {
		return this.call(
			DashboardBannersDescription,
			{
				language,
			},
			token
		).then(
			(response) => {
				if (response['banners'] && Array.isArray(response['banners'])) {
					return response['banners'].map(createDashboardBanner);
				}
				return [];
			}
		);
	}

	public getSignWithAppleInitParameters(brand: Brand, after: string): Promise<SignWithAppleInitParameters> {
		return this.call(
			GetSignWithAppleInitParameters,
			{
				brand,
				after,
			}
		).then(
			(response) => {
				if (response['initParams']) {
					return response['initParams'];
				}
				throw new Error('Invalid response from API received.');
			}
		);
	}

	protected call(method: MethodDescription, params = {}, token = ''): Promise<object> {
		const apiParams = {
			method: method.name,
			shop: this.appConfig.eshopAppCode,
			version: this.appConfig.eshopApiVersion,
			signature: '',
		};

		Object.assign(apiParams, params);

		const signature = this.createSignatureBase(method.name, params, method.fields, method.usesToken ? token : null);
		apiParams.signature = this.signString(signature);

		const requestConfig = {};
		if (method.usesToken && token) {
			requestConfig['headers'] = {
				'Authorization': token
			};
		}

		return this.axios.post('', apiParams, requestConfig)
			.then(
				(response: AxiosResponse) => {
					if (response.status === 200 && response.data && response.data.result === 'ok') {
						return Promise.resolve(response.data);
					}
					if (response.data && response.data.result === 'error' && response.data.errorDetails) {

						if (this.errorHandler && this.errorHandler['addApiError']) {
							this.errorHandler['addApiError'](
								new Error('Failed API call: ' + response.data.errorDetails),
								{
									'whichApi': 'eshop',
									'method': method.name,
									'params': params,
									'response': {
										'result': response.data.result,
										'errorDetails': response.data.errorDetails,
									}
								}
							);
						}

						return Promise.reject(response.data.errorDetails);
					}

					if (this.errorHandler && this.errorHandler['addApiError']) {
						let responseStub = response.data;
						if (typeof responseStub === 'string') {
							if (responseStub.length > 5000) {
								responseStub = responseStub.substr(0, 5000) + '...';
							}
						}
						this.errorHandler['addApiError'](
							new Error('Failed API call, invalid response received'),
							{
								'whichApi': 'eshop',
								'method': method.name,
								'params': params,
								'response': responseStub,
							}
						);
					}

					return Promise.reject('Invalid response from API was received. method ' + method.name);
				},

				(err) => {

					if (this.errorHandler && this.errorHandler['addApiError']) {
						this.errorHandler['addApiError'](
							err,
							{
								'whichApi': 'eshop',
								'method': method.name,
								'params': params,
							}
						);
					}

					throw err;
				}
			)
			;
	}

	protected createSignatureBase(method: string, params: object, fieldsOrder: string[], token = ''): string {
		const fields: string[] = [];
		fields.push(method);
		for (const field of fieldsOrder) {
			fields.push((params[field] || (field.substr(0, 3) === ':::' ? field.substr(3) : '')) + '');
		}
		fields.push(this.appConfig.eshopAppSecret);
		if (token !== null) {
			fields.push(token);
		}
		return fields.join(',');
	}

	protected signString(base: string): string {
		return sha1(base);
	}

}
