export interface Theme {
  name: string;
  properties: any;
}

export const light: Theme = {
  name: 'light',
  properties: {
    '--text-color': '#242529',
    '--background-default': 'linear-gradient(to bottom, #e2f3fb, #e8f6fb, #eff9fc, #f7fcfd, #feffff)',

    '--sidebar-background': 'linear-gradient(148.87deg, #1390AE 0%, #0285C0 89.7%, #0084C2 100%)',

    '--sidebar-light-background': '#fff',
    '--sidebar-dark-background': 'linear-gradient(148.87deg, #1390AE 0%, #0285C0 89.7%, #0084C2 100%)',
    '--sidebar-darkMode-background': '#272727',

    '--card-vocab-background': '#fff',
    '--card-vocab-border': '#E1E0EA',
    '--card-vocab-shadow': '2px 5px 40px 0 rgba(0,149,218,0.25)',

    '--navpanel-background': 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%)',
    '--dropdown-background': '#fff',
    '--dropdown-shadow': '2px 5px 40px 0 rgba(0, 149, 218, 0.25)',
    '--dropdown-items-color': '#669FB9',
    '--dropdown-item-border-color': '#E2F3FB',

    '--circle-progressbar-background': 'white',
    '--user-info-shadow-color': 'rgba(0, 149, 218, 0.25)',

    '--navpanel-button-color-inactive': '#B5B7C0',
    '--study-lesson-container-bg': '#E2F3FB',
    '--course-chapter-bg': '#fff',
    '--container-bg': '#fff',
    '--container-shadow': '2px 5px 40px 0 rgba(0, 149, 218, 0.25)',
    '--container-secondary-bg': '#E2F3FB',

    '--primary-gradient': 'radial-gradient(circle, #0084C2 0%, #0285C0 10.3%, #1390AE 100%)',
    '--dialog-backdrop': 'radial-gradient(circle, #0084C2 0%, #0285C0 10.3%, #1390AE 100%)',
    '--dialog-bg': '#fff',
    '--dialog-text-color': '#333',
    '--dialog-heading-section-color': '#24253D',
    '--dialog-border': '#E1E0EA',
    '--dialog-shadow': '2px 5px 40px 0 rgba(0, 149, 218, 0.25)',
    '--dialog-bottom-heading': '#333',

    '--dropdown-bg-color': '#fff',
    '--dropdown-text-color': '#19233C',

    '--box-shadow': 'rgba(0, 149, 218, 0.25)',
    '--border-color': '#E1E0EA',

    '--btn-text-primary': 'rgba(51, 51, 51, 0.4)',
    '--btn-image-color': '#999999',
    '--btn-active-image-color': '#0095DA',

    '--success-color': 'linear-gradient(33.97deg, #B3D871 0%, #C2D588 100%)',

    '--primary-default': '#3782be',
    '--primary-dark': '#24B286',
    '--primary-light': '#B2FFE7',

    '--primary-text': '#00A2E1',

    '--incorrect-default': '#F88F33',

    '--yellow-color': '#f8d11c',

    '--secondary-default': '#C2D588',

    '--error-default': '#f53d3d',
    '--error-dark': '#800600',
    '--error-light': '#FFCECC',

    '--description-color': '#999999',
    '--user-info-name-color': '#333',

    '--figure-white-icon-color': 'rgba(255,255,255,0.50)',
    '--figure-white-name-color': 'rgba(255,255,255,0.50)',
    '--figure-white-number-color': '#fff',
    '--figure-dark-icon-color': '#B2B2B2',
    '--figure-dark-name-color': '#B2B2B2',
    '--figure-dark-number-color': '#333333',

    '--background-tertiary-shadow': '0 1px 3px 0 rgba(92, 125, 153, 0.5)',
    '--leaderboard-user-card-bg': '#fff',
    '--leaderboard-user-name-text-color': '#333',

    '--vocabulary-summary-card-bg': '#fff',
    '--vocabulary-summary-card-border': '#E1E0EA',
    '--vocabulary-summary-card-title': '#005F8A',
    '--vocabulary-summary-card-description': '#333',
    '--vocabulary-summary-card-review-bg': '#E2F3FB',
    '--vocabulary-search-input-bg': '#fff',

    '--vocabulary-list-item-hover': '#fff',
    '--vocabulary-list-item-active-bg': '#fff',
    '--vocabulary-list-item-active-border': '#E1E0EA',
    '--vocabulary-list-item-excluded-bg': '#FAFCFD',
    '--vocabulary-list-item-text-main': '#333',
    '--vocabulary-list-item-text-secondary': '#999',
    '--vocabulary-list-item-text-tertiary': '#B2B2B2',

    '--vocabulary-select-chapter-filter-text': '#333',
    '--vocabulary-select-text-color': '#19233C',

    '--period-dropdown-text': '#19233C',

    '--input-color': 'rgba(33, 37, 41, 1)',
    '--input-disabled': 'rgba(0, 0, 0, 0.38)',
    '--input-label-color': 'rgba(0, 0, 0, 0.54)',

    '--user-info-profile-container-text': '#333',
    '--user-info-profile-menu-item': '#333',
    '--user-info-profile-menu-item-disabled': 'lightgray',

    '--course-description': '#19233C',
    '--course-item-title': '#333',
    '--course-chapter-title': '#1d1d26',
    '--course-chapter-subtitle': '#1d1d26',
    '--course-week-locked-title': '#1d1d26',
    '--course-week-locked-subtitle': '#b2b2b2',
    '--course-week-title': '#1d1d26',
    '--course-lesson-n+1-bg': 'rgb(235,245,251)',
    '--course-lesson-title': '#333',
    '--course-chapter-week-bg': '#fff',
    '--course-chapter-week-active-bg': '#fff',
    '--course-chapter-week-active-box-shadow': '-16px 1px 22px 0 rgba(0, 149, 218, 0.25)',
    '--course-chapter-week-box-2-bg': 'linear-gradient(270deg, #ECF7FD 0%, #FFFFFF 100%)',
    '--course-chapter-week-box-2-shadow': '-30px 5px 60px 0 rgba(0,149,218,0.25)',

    '--eshop-language-filter-bg': '#e2f3fb',
    '--eshop-language-filter-shadow': '2px 5px 40px 0 rgba(0,149,218,0.25)',
    '--eshop-language-filter-label': '#19233c',

    '--lesson-container-bg': '#fff',
    '--lesson-container-box-border-color': '#E1E0EA',
    '--lesson-container-box-shadow': '2px 5px 40px 0 rgba(#0095DA, 0.25)',
    '--lesson-container-secondary-bg': '#e2f3fb',
    '--lesson-text-title-color': '#333',
    '--lesson-sort-sentence-bg': 'rgba(226, 243, 251, 0.59)',
    '--lesson-assig-groups-dropzone-bg': '#F2F2F2',
    '--lesson-crossword-input': '#E1F2FA',
    '--lesson-crossword-input-static': '#EEE',
    '--lesson-crossword-text-color': '#000',
    '--lesson-primary-border': '#0793c8',
    '--lesson-word-should-be-correct-bg': 'none',
    '--lesson-word-should-be-correct-txt': '#333',

    '--text-input-bg': '#E2F3FB',
    '--text-input-active-bg': '#fff',
    '--text-input-active-border-color': '#0095da',
    '--text-input-text-color': '#333',
    '--text-input-active-text-color': '#333',

    '--vocabulary-item-bg': 'transparent',
    '--vocabulary-item-active-bg': '#fff',
    '--vocabulary-item-active-border': '#E1E0EA',
    '--vocabulary-item-active-shadow': '2px 5px 40px 0 rgba(0, 149, 218, 0.25)',

    '--layout-user-hover-bg': '#E2F3FB',
    '--select-not-empty-text-color': '#19233C',
    '--settings-item-text-color': '#212529FF',

    '--exercise-text-into-color': '#333333FF',
    '--vocabulary-exercise-item-title-color': '#333333FF',
    '--fill-in-word-bg': '#E2F3FB',
    '--fill-in-active-word-bg': '#fff',

    '--payment-dialog-tile-bg': '#fff',
    '--payment-dialog-tile-border': '#E1E0EA',
    '--payment-dialog-tile-shadow': '2px 5px 40px 0 rgba(0, 149, 218, 0.25)',
    '--payment-dialog-header': '#363940',
    '--payment-dialog-text': '#363940',
    '--payment-dialog-head': '#333333',
    '--payment-dialog-close-btn-shadow': '4px 4px 16px 11px rgba(0,149,218,0.15)',

    '--lesson-sidebar-header': '#1D1D26',
    '--lesson-sidebar-current-lesson-shadow': '0 -6px 35px 0 rgba(71,159,221,0.25)',
    '--lesson-sidebar-lesson-title': '#333',

    '--link-btn-color': '#212529',
    '--empty-input-bg': '#E2F3FB',
    '--active-input-border': '#0095DA',
    '--profile-picture-shadow': '0 -6px 29px 0 rgba(0, 149, 218, 0.25)',
    '--container-shadow-color': 'rgba(0,149,218,0.25)',
    '--current-lesson-shadow-color': 'rgba(0,95,138,0.25)',
    '--course-week-current-lesson-shadow': 'rgba(71,159,221,0.25)',
    '--main-background': 'linear-gradient(to bottom, #e2f3fb, #e8f6fb, #eff9fc, #f7fcfd, #feffff)',
    '--topbar-border-color': '#DAE8F0',
    '--vocab-training-word-color': '#1D1D26',
    '--sort-words-correct-word': '#000',
    '--text-info-text-color': '#333333FF',
    '--sort-words-box-shadow': '0 -6px 36px 0 #479FDDFF',
    '--true-false-btn-bg': '#ECF0F0',
    '--editor-default-black': '#3b3f41',
    '--lang-filter-label': '#19233C',
    '--bookmark-lessons-bg': 'linear-gradient(270deg, #ECF7FD 0%, #FFFFFF 100%)',
    '--bookmark-lessons-shadow': '3px 1px 22px 0 rgba(0,149,218,0.25)',
    '--bookmark-lesson-text': 'color: rgba(51, 51, 51, 0.7)',
    '--bookmark-lesson-hover-bg': '#fff',
    '--lesson-dropdown-theme-name': '#19233C'
  }
};

export const dark: Theme = {
  name: 'dark',
  properties: {
    '--text-color': '#242529',
    '--background-default': 'linear-gradient(148.87deg, #0084C2 0%, #0285C0 10.3%, #1390AE 100%)',

    '--sidebar-background': '#fff',

    '--sidebar-light-background': '#fff',
    '--sidebar-dark-background': 'linear-gradient(148.87deg, #1390AE 0%, #0285C0 89.7%, #0084C2 100%)',
    '--sidebar-darkMode-background': '#272727',

    '--card-vocab-background': '#fff',
    '--card-vocab-border': '#E1E0EA',
    '--card-vocab-shadow': '2px 5px 40px 0 rgba(0,149,218,0.25)',

    '--navpanel-background': 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%)',
    '--dropdown-background': '#fff',
    '--dropdown-shadow': '2px 5px 40px 0 rgba(0, 149, 218, 0.25)',
    '--dropdown-items-color': '#669FB9',
    '--dropdown-item-border-color': '#E2F3FB',

    '--circle-progressbar-background': 'white',
    '--user-info-shadow-color': 'rgba(0, 149, 218, 0.25)',

    '--navpanel-button-color-inactive': '#B5B7C0',
    '--study-lesson-container-bg': '#E2F3FB',
    '--course-chapter-bg': '#fff',
    '--container-bg': '#fff',
    '--container-shadow': '2px 5px 40px 0 rgba(0, 149, 218, 0.25)',
    '--container-secondary-bg': '#E2F3FB',

    '--primary-gradient': 'radial-gradient(circle, #0084C2 0%, #0285C0 10.3%, #1390AE 100%)',
    '--dialog-backdrop': 'radial-gradient(circle, #0084C2 0%, #0285C0 10.3%, #1390AE 100%)',
    '--dialog-bg': '#fff',
    '--dialog-text-color': '#333',
    '--dialog-heading-section-color': '#24253D',
    '--dialog-border': '#E1E0EA',
    '--dialog-shadow': '2px 5px 40px 0 rgba(0, 149, 218, 0.25)',
    '--dialog-bottom-heading': '#333',

    '--dropdown-bg-color': '#fff',
    '--dropdown-text-color': '#19233C',

    '--box-shadow': 'rgba(0, 149, 218, 0.25)',
    '--border-color': '#E1E0EA',

    '--btn-text-primary': 'rgba(255,255,255,0.6)',
    '--success-color': 'linear-gradient(33.97deg, #B3D871 0%, #C2D588 100%)',
    '--btn-image-color': '#999999',
    '--btn-active-image-color': '#0095DA',

    '--primary-default': '#3782be',
    '--primary-dark': '#24B286',
    '--primary-light': '#B2FFE7',

    '--primary-text': '#00A2E1',

    '--secondary-default': '#C2D588',
    '--incorrect-default': '#F88F33',


    '--error-default': '#EF3E36',
    '--error-dark': '#800600',
    '--error-light': '#FFCECC',

    '--description-color': '#999999',
    '--user-info-name-color': '#333',

    '--figure-white-icon-color': 'rgba(255,255,255,0.50)',
    '--figure-white-name-color': 'rgba(255,255,255,0.50)',
    '--figure-white-number-color': '#fff',
    '--figure-dark-icon-color': '#B2B2B2',
    '--figure-dark-name-color': '#B2B2B2',
    '--figure-dark-number-color': '#333333',

    '--leaderboard-user-card-bg': '#fff',
    '--leaderboard-user-name-text-color': '#333',

    '--vocabulary-summary-card-bg': '#fff',
    '--vocabulary-summary-card-border': '#E1E0EA',
    '--vocabulary-summary-card-title': '#005F8A',
    '--vocabulary-summary-card-description': '#333',
    '--vocabulary-summary-card-review-bg': '#E2F3FB',
    '--vocabulary-search-input-bg': '#fff',

    '--vocabulary-list-item-hover': '#fff',
    '--vocabulary-list-item-active-bg': '#fff',
    '--vocabulary-list-item-active-border': '#E1E0EA',
    '--vocabulary-list-item-excluded-bg': '#FAFCFD',
    '--vocabulary-list-item-text-main': '#333',
    '--vocabulary-list-item-text-secondary': '#999',
    '--vocabulary-list-item-text-tertiary': '#B2B2B2',

    '--vocabulary-select-chapter-filter-text': '#333',
    '--vocabulary-select-text-color': '#19233C',

    '--period-dropdown-text': '#19233C',

    '--input-color': 'rgba(33, 37, 41, 1)',
    '--input-disabled': 'rgba(0, 0, 0, 0.38)',
    '--input-label-color': 'rgba(0, 0, 0, 0.54)',

    '--user-info-profile-container-text': '#333',
    '--user-info-profile-menu-item': '#333',
    '--user-info-profile-menu-item-disabled': 'lightgray',

    '--course-description': '#19233C',
    '--course-item-title': '#333',
    '--course-chapter-title': '#1d1d26',
    '--course-chapter-subtitle': '#1d1d26',
    '--course-week-locked-title': '#1d1d26',
    '--course-week-locked-subtitle': '#b2b2b2',
    '--course-week-title': '#1d1d26',
    '--course-lesson-n+1-bg': 'rgb(235,245,251)',
    '--course-lesson-title': '#333',
    '--course-chapter-week-bg': '#fff',
    '--course-chapter-week-active-bg': '#fff',
    '--course-chapter-week-active-box-shadow': '-16px 1px 22px 0 rgba(0, 149, 218, 0.25)',
    '--course-chapter-week-box-2-bg': 'linear-gradient(270deg, #ECF7FD 0%, #FFFFFF 100%)',
    '--course-chapter-week-box-2-shadow': '-30px 5px 60px 0 rgba(0,149,218,0.25)',

    '--eshop-language-filter-bg': '#e2f3fb',
    '--eshop-language-filter-shadow': '2px 5px 40px 0 rgba(0,149,218,0.25)',
    '--eshop-language-filter-label': '#19233c',

    '--lesson-container-bg': '#fff',
    '--lesson-container-box-border-color': '#E1E0EA',
    '--lesson-container-box-shadow': '2px 5px 40px 0 rgba(#0095DA, 0.25)',
    '--lesson-container-secondary-bg': '#e2f3fb',
    '--lesson-text-title-color': '#333',
    '--lesson-sort-sentence-bg': 'rgba(226, 243, 251, 0.59)',
    '--lesson-assig-groups-dropzone-bg': '#F2F2F2',
    '--lesson-crossword-input': '#E1F2FA',
    '--lesson-crossword-input-static': '#EEE',
    '--lesson-crossword-text-color': '#000',
    '--lesson-primary-border': '#0793c8',
    '--lesson-word-should-be-correct-bg': 'none',
    '--lesson-word-should-be-correct-txt': '#333',

    '--text-input-bg': '#E2F3FB',
    '--text-input-active-bg': '#fff',
    '--text-input-active-border-color': '#0095da',
    '--text-input-text-color': '#333',
    '--text-input-active-text-color': '#333',

    '--vocabulary-item-bg': 'transparent',
    '--vocabulary-item-active-bg': '#fff',
    '--vocabulary-item-active-border': '#E1E0EA',
    '--vocabulary-item-active-shadow': '2px 5px 40px 0 rgba(0, 149, 218, 0.25)',

    '--layout-user-hover-bg': '#E2F3FB',
    '--select-not-empty-text-color': '#19233C',
    '--settings-item-text-color': '#212529FF',

    '--exercise-text-into-color': '#333333FF',
    '--vocabulary-exercise-item-title-color': '#333333FF',
    '--fill-in-word-bg': '#E2F3FB',
    '--fill-in-active-word-bg': '#fff',

    '--payment-dialog-tile-bg': '#fff',
    '--payment-dialog-tile-border': '#E1E0EA',
    '--payment-dialog-tile-shadow': '2px 5px 40px 0 rgba(0, 149, 218, 0.25)',
    '--payment-dialog-header': '#363940',
    '--payment-dialog-text': '#363940',
    '--payment-dialog-head': '#333333',
    '--payment-dialog-close-btn-shadow': '4px 4px 16px 11px rgba(0,149,218,0.15)',

    '--lesson-sidebar-header': '#1D1D26',
    '--lesson-sidebar-current-lesson-shadow': '0 -6px 35px 0 rgba(71,159,221,0.25)',
    '--lesson-sidebar-lesson-title': '#333',

    '--link-btn-color': '#212529',
    '--empty-input-bg': '#E2F3FB',
    '--active-input-border': '#0095DA',
    '--profile-picture-shadow': '0 -6px 29px 0 rgba(0, 149, 218, 0.25)',
    '--container-shadow-color': 'rgba(0,149,218,0.25)',
    '--current-lesson-shadow-color': 'rgba(0,95,138,0.25)',
    '--course-week-current-lesson-shadow': 'rgba(71,159,221,0.25)',
    '--main-background': 'linear-gradient(to bottom, #e2f3fb, #e8f6fb, #eff9fc, #f7fcfd, #feffff)',
    '--topbar-border-color': '#DAE8F0',
    '--vocab-training-word-color': '#1D1D26',
    '--sort-words-correct-word': '#000',
    '--text-info-text-color': '#333333FF',
    '--sort-words-box-shadow': '0 -6px 36px 0 #479FDDFF',
    '--true-false-btn-bg': '#ECF0F0',
    '--editor-default-black': '#3b3f41',
    '--lang-filter-label': '#19233C',
    '--bookmark-lessons-bg': 'linear-gradient(270deg, #ECF7FD 0%, #FFFFFF 100%)',
    '--bookmark-lessons-shadow': '3px 1px 22px 0 rgba(0,149,218,0.25)',
    '--bookmark-lesson-text': 'color: rgba(51, 51, 51, 0.7)',
    '--bookmark-lesson-hover-bg': '#fff',
    '--lesson-dropdown-theme-name': '#19233C'
  }
};

export const darkMode: Theme = {
  name: 'darkMode',
  properties: {
    '--text-color': 'rgba(255,255,255, 0.8)',
    '--background-default': 'linear-gradient(148.87deg, #111 0%, #111 10.3%, #111 100%)',

    '--sidebar-background': '#272727',

    '--sidebar-light-background': '#272727',
    '--sidebar-dark-background': '#272727',
    '--sidebar-darkMode-background': '#272727',

    '--card-vocab-background': '#272727',
    '--card-vocab-border': '#000',
    '--card-vocab-shadow': '2px 5px 40px 0 rgba(0,149,218,0.25)',

    '--navpanel-background': 'linear-gradient(180deg, #272727 0%, #272727 100%)',
    '--dropdown-background': '#272727',
    '--dropdown-shadow': '2px 5px 40px 0 #111',
    '--dropdown-items-color': '#669FB9',
    '--dropdown-item-border-color': '#333',

    '--circle-progressbar-background': '#272727',
    '--user-info-shadow-color': 'rgba(0, 149, 218, 0.25)',

    '--navpanel-button-color-inactive': '#999',
    '--study-lesson-container-bg': '#272727',
    '--course-chapter-bg': '#272727',
    '--container-bg': '#272727',
    '--container-shadow': '2px 5px 40px 0 rgba(0, 0, 0, 0.25)',
    '--container-secondary-bg': '#333',

    '--primary-gradient': 'radial-gradient(circle, #0084C2 0%, #0285C0 10.3%, #1390AE 100%)',
    '--dialog-backdrop': 'radial-gradient(circle, rgba(39, 39, 39, 0.8) 0%, rgba(39, 39, 39, 0.8) 10.3%, rgba(17, 17, 17, 1) 100%)',
    '--dialog-bg': '#272727',
    '--dialog-text-color': 'rgba(255,255,255, 0.8)',
    '--dialog-heading-section-color': 'rgba(255,255,255, 0.8)',
    '--dialog-border': '#272727',
    '--dialog-shadow': 'none',
    '--dialog-bottom-heading': 'rgba(255,255,255, 0.8)',

    '--dropdown-bg-color': '#272727',
    '--dropdown-text-color': 'rgba(255,255,255, 0.8)',

    '--box-shadow': 'rgba(0, 149, 218, 0.25)',
    '--border-color': '#000',

    '--btn-text-primary': 'rgba(255,255,255,0.6)',
    '--success-color': 'linear-gradient(33.97deg, #B3D871 0%, #C2D588 100%)',
    '--btn-image-color': '#999999',
    '--btn-active-image-color': '#0095DA',

    '--primary-default': '#3782be',
    '--primary-dark': '#24B286',
    '--primary-light': '#B2FFE7',

    '--primary-text': '#00A2E1',

    '--secondary-default': '#C2D588',
    '--incorrect-default': '#F88F33',
    '--error-default': '#EF3E36',
    '--error-dark': '#800600',
    '--error-light': '#FFCECC',

    '--description-color': '#999999',
    '--user-info-name-color': 'rgba(255,255,255, 0.8)',

    '--figure-white-icon-color': 'rgba(255,255,255,0.50)',
    '--figure-white-name-color': 'rgba(255,255,255,0.50)',
    '--figure-white-number-color': '#fff',
    '--figure-dark-icon-color': '#B2B2B2',
    '--figure-dark-name-color': '#B2B2B2',
    '--figure-dark-number-color': 'rgba(255,255,255, 0.8)',

    '--leaderboard-user-card-bg': '#272727',
    '--leaderboard-user-name-text-color': 'rgba(255,255,255, 0.8)',

    '--vocabulary-summary-card-bg': '#272727',
    '--vocabulary-summary-card-border': '#272727',
    '--vocabulary-summary-card-title': '#005F8A',
    '--vocabulary-summary-card-description': 'rgba(255,255,255, 0.8)',
    '--vocabulary-summary-card-review-bg': '#272727',
    '--vocabulary-search-input-bg': '#272727',

    '--vocabulary-list-item-hover': '#272727',
    '--vocabulary-list-item-active-bg': '#272727',
    '--vocabulary-list-item-active-border': '#272727',
    '--vocabulary-list-item-excluded-bg': '#272727',
    '--vocabulary-list-item-text-main': 'rgba(255,255,255, 0.8)',
    '--vocabulary-list-item-text-secondary': '#999',
    '--vocabulary-list-item-text-tertiary': '#B2B2B2',

    '--vocabulary-select-chapter-filter-text': 'rgba(255,255,255, 0.8)',
    '--vocabulary-select-text-color': 'rgba(255,255,255, 0.8)',

    '--period-dropdown-text': 'rgba(255,255,255, 0.8)',

    '--input-color': 'rgba(255, 255, 255, 1)',
    '--input-disabled': 'rgba(255, 255, 255, 0.38)',
    '--input-label-color': 'rgba(255, 255, 255, 0.54)',

    '--user-info-profile-container-text': 'rgba(255,255,255, 0.8)',
    '--user-info-profile-menu-item': 'rgba(255,255,255, 0.8)',
    '--user-info-profile-menu-item-disabled': 'rgba(255,255,255,0.49)',

    '--course-description': 'rgba(255,255,255, 0.8)',
    '--course-item-title': 'rgba(255,255,255, 0.8)',
    '--course-chapter-title': 'rgba(255,255,255, 0.8)',
    '--course-chapter-subtitle': 'rgba(255,255,255, 0.8)',
    '--course-week-locked-title': 'rgba(255,255,255, 0.8)',
    '--course-week-locked-subtitle': 'rgba(255,255,255, 0.8)',
    '--course-week-title': 'rgba(255,255,255, 0.8)',
    '--course-lesson-n+1-bg': '#333',
    '--course-lesson-title': 'rgba(255,255,255, 0.8)',
    '--course-chapter-week-bg': '#272727',
    '--course-chapter-week-active-bg': '#272727',
    '--course-chapter-week-active-box-shadow': '-16px 1px 22px 0 #272727',
    '--course-chapter-week-box-2-bg': 'linear-gradient(270deg, #272727 0%, #272727 100%)',
    '--course-chapter-week-box-2-shadow': '-30px 5px 60px 0 #272727',

    '--eshop-language-filter-bg': '#272727',
    '--eshop-language-filter-shadow': '2px 5px 40px 0 rgba(0,149,218,0.25)',
    '--eshop-language-filter-label': 'rgba(255,255,255, 0.8)',

    '--lesson-container-bg': '#333',
    '--lesson-container-box-border-color': '#333',
    '--lesson-container-box-shadow': '2px 5px 40px 0 rgba(0, 0, 0, 0.25)',
    '--lesson-container-secondary-bg': '#272727',
    '--lesson-text-title-color': '#ecf0f0',
    '--lesson-sort-sentence-bg': '#272727',
    '--lesson-assig-groups-dropzone-bg': '#333',
    '--lesson-crossword-input': '#272727',
    '--lesson-crossword-input-static': '#282727',
    '--lesson-crossword-text-color': 'rgba(255,255,255, 0.8)',
    '--lesson-primary-border': '#333',
    '--lesson-word-should-be-correct-bg': '#272727',
    '--lesson-word-should-be-correct-txt': 'rgba(255,255,255, 0.8)',

    '--text-input-bg': '#272727',
    '--text-input-active-bg': '#333',
    '--text-input-active-border-color': '#272727',
    '--text-input-text-color': 'rgba(255,255,255, 0.8)',
    '--text-input-active-text-color': 'rgba(255,255,255, 0.8)',

    '--vocabulary-item-bg': '#333',
    '--vocabulary-item-active-bg': '#272727',
    '--vocabulary-item-active-border': '#333',
    '--vocabulary-item-active-shadow': 'none',

    '--layout-user-hover-bg': '#272727',
    '--select-not-empty-text-color': 'rgba(255,255,255, 0.8)',
    '--settings-item-text-color': 'rgba(255,255,255, 0.8)',

    '--exercise-text-into-color': '#ecf0f0',
    '--vocabulary-exercise-item-title-color': '#ecf0f0',
    '--fill-in-word-bg': '#272727',
    '--fill-in-active-word-bg': '#333',

    '--payment-dialog-tile-bg': '#272727',
    '--payment-dialog-tile-border': '#272727',
    '--payment-dialog-tile-shadow': '2px 5px 40px 0 #272727',
    '--payment-dialog-header': 'rgba(255,255,255, 0.8)',
    '--payment-dialog-text': 'rgba(255,255,255, 0.8)',
    '--payment-dialog-head': 'rgba(255,255,255, 0.8)',
    '--payment-dialog-close-btn-shadow': 'none',

    '--lesson-sidebar-header': 'rgba(255,255,255, 0.8)',
    '--lesson-sidebar-current-lesson-shadow': '0 -6px 35px 0 rgba(71,159,221,0.1)',
    '--lesson-sidebar-lesson-title': 'rgba(255,255,255, 0.8)',

    '--link-btn-color': '#fff',
    '--empty-input-bg': '#1e1e1e',
    '--active-input-border': '#1e1e1e',
    '--profile-picture-shadow': '0 -6px 29px 0 rgb(4 7 9 / 57%)',
    '--container-shadow-color': '#272727',
    '--current-lesson-shadow-color': '#272727',
    '--course-week-current-lesson-shadow': '#272727',
    '--main-background': 'linear-gradient(to bottom, #111, #111, #111, #111, #111)',
    '--topbar-border-color': 'rgba(29, 29, 38, 0.5)',
    '--vocab-training-word-color': '#ecf0f0',
    '--sort-words-correct-word': '#ecf0f0',
    '--text-info-text-color': '#ecf0f0',
    '--sort-words-box-shadow': '0 -6px 36px 0 #272727',
    '--true-false-btn-bg': '#272727',
    '--editor-default-black': '#ecf0f0',
    '--lang-filter-label': '#ecf0f0',
    '--bookmark-lessons-bg': '#272727',
    '--bookmark-lessons-shadow': '3px 1px 22px 0 rgba(0,149,218,0.25)',
    '--bookmark-lesson-text': 'color: rgba(255,255,255, 0.8)',
    '--bookmark-lesson-hover-bg': '#272727',
    '--lesson-dropdown-theme-name': '#ecf0f0'
  }
};

export const APP_THEMES = {
  light,
  dark,
  darkMode
};
