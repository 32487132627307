import {Injectable} from '@angular/core';
import {Theme, light, dark, darkMode} from './theme';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private LOCAL_STORAGE_KEY = 'oj-darkMode';

  private active: Theme = light;

  public availableThemes: Theme[] = [light, dark];

  public themeChanged: Subject<Theme> = new Subject<Theme>();

  public isDarkMode: boolean = false;

  public darkModeChanged: Subject<boolean> = new Subject<boolean>();

  public currentTheme: Theme = light;

  constructor() {
    this.getStoredTheme();
  }

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

  isDarkTheme(): boolean {
    return this.active.name === dark.name;
  }

  setDarkTheme(): void {
    this.setActiveTheme(dark);
  }

  setLightTheme(): void {
    this.setActiveTheme(light);
  }

  getDarkMode(): boolean {
    return this.isDarkMode;
  }

  setDarkMode(isDarkMode: boolean): void {
    this.isDarkMode = isDarkMode;
    this.darkModeChanged.next(isDarkMode);
  }

  toggleDarkMode(): void {
    this.isDarkMode = !this.isDarkMode;
    this.darkModeChanged.next(this.isDarkMode);

    if (this.isDarkMode) {
      this.setActiveTheme(darkMode);
    } else {
      this.setActiveTheme(this.currentTheme);
    }
  }

  setActiveTheme(theme: Theme): void {
    if (theme !== darkMode) this.currentTheme = theme;

    if (this.isDarkMode && theme !== darkMode) return;

    this.active = theme;
    this.themeChanged.next(theme);

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });

    this.storeActiveTheme();
  }

  storeActiveTheme(): void {
    // set true if active theme is darkMode
    localStorage.setItem(this.LOCAL_STORAGE_KEY, this.isDarkMode ? 'true' : 'false');
  }

  getStoredTheme(): Theme {
    const stored = localStorage.getItem(this.LOCAL_STORAGE_KEY);

    if (stored === 'true') {
      this.setDarkMode(true);
      this.setActiveTheme(darkMode);
    } else {
      this.setDarkMode(false);
      this.setActiveTheme(light);
    }

    return this.active;
  }

}
