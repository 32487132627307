import {Component, Input, OnInit} from '@angular/core';
import {ThemeService} from '../../theme/theme.service';

@Component({
  selector: 'app-circle-progressbar',
  templateUrl: './circle-progressbar.component.html',
  styleUrls: ['./circle-progressbar.component.scss']
})
export class CircleProgressbarComponent implements OnInit {

  @Input() size: number;
  @Input() percentage: number;
  @Input() imageUrl: string;
  @Input() imageSizePercentage = '40';
  @Input() borderWidth = 3;
  @Input() borderColor: string;
  @Input() innerStrokeColor = 'rgba(0,149,218,0.9)';
  @Input() circleBackground = true;
  @Input() circleBackgroundColor = '';
  @Input() roundedImage = true;

  @Input() exerciseDone = false;
  @Input() exerciseActive = false;
  constructor(
    private themeService: ThemeService
  ) { }

  ngOnInit() {
  }

  getCircleBackgroundColor() {

    if (this.themeService.isDarkMode) {
      return 'transparent';
    }

    if (this.exerciseActive) {
      return this.exerciseDone ? '#B3D871' : 'var(--circle-progressbar-background)';
    }
    return this.circleBackground ? this.circleBackgroundColor : 'transparent';
  }

  getBorderColor() {
    return this.exerciseDone ? '#B3D871' : 'white';
  }

  getImageUrl() {
    if (this.exerciseActive) {

      return this.exerciseDone ? '/assets/img/icons/checked.svg' : this.imageUrl;

    }

    return 'none';
  }

}
