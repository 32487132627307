import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CurrentUserService} from '../../auth/current-user.service';
import {StudovnaApi} from '../../../../oj-app-common/studovna-api/studovna-api';
import {MyProfileResponse} from '../../../../oj-app-common/studovna-api/responses/my-profile';
import {Router} from '@angular/router';
import {LoginService} from '../../services/login.service';
import {CurrentCourseService} from '../../services/current-course-service';
import {OverlayService} from '../overlay-dialog/overlay-service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {AppConfig} from '../../config/app-config';
import {ReportBugService, ReportProblemType} from '../../services/report-bug.service';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {ThemeService} from '../../theme/theme.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  portraitUrl;

  profileDetails: MyProfileResponse;

  REPORT_PROBLEM_TYPES = ReportProblemType;

  tempUser = '';

  @Input() theme = 'light';

  @ViewChild(BsDropdownDirective, {static: false}) bsDropdown: BsDropdownDirective;

  @Input() onlyInTrialMode = false;

  constructor(
    protected currentUserService: CurrentUserService,
    protected studovnaApi: StudovnaApi,
    protected router: Router,
    protected loginService: LoginService,
    protected currentCourseService: CurrentCourseService,
    protected overlayService: OverlayService,
    protected messageService: MessageService,
    protected translateService: TranslateService,
    protected appConfig: AppConfig,
    protected reportBugService: ReportBugService,
    public themeService: ThemeService
  ) {
  }

  ngOnInit() {
    this.initUserProfile();

    this.currentCourseService
      .onSwitchCourse
      .subscribe(() => {
        return this.initUserProfile();
      });

    const profile = this.currentUserService.profile;

    if (profile && profile.email) {
      this.tempUser = profile.email;
    }

    this.currentUserService
      .userChanged
      .subscribe(() => this.initUserProfile());
  }

  protected async initUserProfile() {
    this.portraitUrl = this.currentUserService.profile.getPortraitUrl();
    this.profileDetails = await this.currentUserService.getMyProfileFromLocalStorage();
  }

  clickedSettings() {
    this.router.navigate(['/settings']);
  }

  clickedLogout() {
    this.loginService
      .logout()
      .then(() => {
        this.currentUserService.removeFromStorage();
        this.router.navigate(['/login']);
      }, () => {
        this.currentUserService.removeFromStorage();
        this.router.navigate(['/login']);
      });
  }

  showReportBugDialog(type: ReportProblemType) {
    this.reportBugService
      .reportBug
      .next({
        type,
        ignoreScreen: true,
        courseId: this.currentCourseService.course ? this.currentCourseService.course.id : 0,
        lessonId: this.currentCourseService.getCurrentLesson() ? this.currentCourseService.getCurrentLesson().id : 0,
        wordId: 0
      });
  }

  getUserName(profile: MyProfileResponse) {
    if (!profile) {
      return '';
    }

    if (profile.firstName) {
      return profile.firstName;
    }

    if (profile.userName) {
      if (profile.userName.indexOf('@') !== -1) {
        return profile.userName.substring(0, profile.userName.indexOf('@'));
      }
      return profile.userName;
    }
    if (profile.firstName.indexOf('@') !== -1) {
      return profile.firstName.substring(0, this.profileDetails.firstName.indexOf('@'));
    }
    return profile.firstName;
  }

  darkThemeToggle(e: Event) {
    e.stopPropagation();
    this.themeService.toggleDarkMode();
  }

}
