import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AppAdsService} from '../../services/app-ads.service';
import {Observable, Subscription} from 'rxjs';
import {PaymentDialogService} from '../payment-dialogs/payment-dialog.service';
import {Router} from '@angular/router';
import {RubyCalculatorService} from '../../pages/base-layout-page/pages/eshop-page/services/ruby-calculator-service';
import {CurrentCourseService} from '../../services/current-course-service';
import {EshopUserPreferencesService} from '../../pages/base-layout-page/pages/eshop-page/services/eshop-user-preferences.service';

@Component({
  selector: 'app-shop-yellow-banner',
  templateUrl: './shop-yellow-banner.component.html',
  styleUrls: ['./shop-yellow-banner.component.scss']
})
export class ShopYellowBannerComponent implements OnInit, OnDestroy {

  showBanner: Observable<boolean>;

  showBannerSubscription: Subscription;

  @Output() showedBanner: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    protected appAdsService: AppAdsService,
    protected paymentService: PaymentDialogService,
    protected router: Router,
    protected rubyCalcService: RubyCalculatorService,
    protected currentCourse: CurrentCourseService,
    protected eshopPrefs: EshopUserPreferencesService
  ) {
  }

  ngOnInit() {
    this.showBanner = this.appAdsService.showYellowBanner;
  }

  ngOnDestroy() {
    if (this.showBannerSubscription) {
      this.showBannerSubscription.unsubscribe();
    }
  }

  async onSubscribeButtonClick(e: Event) {
    e.preventDefault();

    const rubyCalc = await this.rubyCalcService.getCalculator();
    const courseId = this.currentCourse.courseId;
    const productId = this.currentCourse.productId;
    const courseCombinedModel = rubyCalc.getCourseData(courseId);

    if (!courseCombinedModel || !courseCombinedModel.id_study) {
      this.router.navigate(['/eshop'], {queryParams: {currentTab: 0}});

      return;
    }

    const shopOptions = rubyCalc.getShopOptionsOfCourse(courseCombinedModel.id_study, this.eshopPrefs.currency);
    try {
      await this.paymentService.handleButtonClick(
        'get', courseCombinedModel, shopOptions, productId
      );
    } catch (e) {
      this.router.navigate(['/eshop']);
      console.warn(e);
    }
  }

}
